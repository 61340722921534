import { FrontAboutMessages } from './aboutPageTranslations';
import { H2 } from 'primitives/appTitle';
import {
  ShIntlMessageDefinition,
  ShText,
  useShTranslate,
} from '@shoootin/translations';
import AppSection from 'primitives/appSection';
import React from 'react';
import AppLinkBar from 'primitives/appLinkBar';
import FirstLetterSvg from 'components/svg/alphabet/firstLetterSvg';
import { LinkData } from '../../appTypes';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

const EngagementPanelNumbers = [1, 2, 3] as const;
type EngagementPanelNumber = typeof EngagementPanelNumbers[number];

const getEngagementPanelMessages = (
  panelNumber: EngagementPanelNumber,
): {
  title: ShIntlMessageDefinition;
  p1: ShIntlMessageDefinition;
  p2: ShIntlMessageDefinition;
} => ({
  title:
    // @ts-ignore
    FrontAboutMessages[`pageSectionEngagementsPanels_${panelNumber}_title`],
  // @ts-ignore
  p1: FrontAboutMessages[`pageSectionEngagementsPanels_${panelNumber}_p1`],
  // @ts-ignore
  p2: FrontAboutMessages[`pageSectionEngagementsPanels_${panelNumber}_p2`],
});

const EngagementPanel = ({
  panelNumber,
  images,
}: {
  panelNumber: EngagementPanelNumber;
  images: AppGatsbyImageFluid[];
}) => {
  const translate = useShTranslate();
  const panelMessages = getEngagementPanelMessages(panelNumber);
  return (
    <div className="tabset-panel">
      <div className="about-pledge__content">
        <div className="about-pledge__image tabset-image" data-parallax="0.2">
          <AppGatsbyImage fluid={images[panelNumber - 1]} />
        </div>
        <div
          className="about-pledge__caption tabset-caption"
          data-parallax="0.3"
        >
          <H2>
            <ShText message={panelMessages.title} />
          </H2>
          <p>
            <ShText message={panelMessages.p1} />
          </p>
          <p>
            <ShText message={panelMessages.p2} />
          </p>
        </div>
      </div>
      <div className="about-pledge__graphic tabset-graphic" data-parallax="0.1">
        <FirstLetterSvg forText={translate(panelMessages.title)} />
      </div>
    </div>
  );
};

const AboutPledgeNav: LinkData[] = [
  { label: getEngagementPanelMessages(1).title, href: '#' },
  { label: getEngagementPanelMessages(2).title, href: '#' },
  {
    label: getEngagementPanelMessages(3).title,
    href: '#',
  },
];

const AboutPageEngagementsSection = ({
  images,
}: {
  images: AppGatsbyImageFluid[];
}) => (
  <AppSection
    header={
      <H2>
        <ShText message={FrontAboutMessages.pageSectionEngagementsTitle} />
      </H2>
    }
    className="about-pledge"
  >
    <div className="tabset tabset-pledge">
      <AppLinkBar links={AboutPledgeNav} modifier="tabset-nav" />
      <div className="tabset-content">
        {EngagementPanelNumbers.map((panelNumber) => (
          <EngagementPanel
            key={panelNumber}
            panelNumber={panelNumber}
            images={images}
          />
        ))}
      </div>
    </div>
  </AppSection>
);

export default AboutPageEngagementsSection;
