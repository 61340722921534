import React, { ReactNode } from 'react';
import classnames from 'classnames';

const Callout = ({
  imgSrc,
  children,
}: {
  imgSrc: string;
  children: ReactNode;
}) => (
  <div className="callout" style={{ backgroundImage: `url(${imgSrc})` }}>
    <div className="container">{children}</div>
  </div>
);

export default Callout;
