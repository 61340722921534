import React, { useEffect } from 'react';
import { MainLayout } from '../../layout/mainLayout';
import HeroTitle from '../../components/hero/heroTitle';
import { H1, H2 } from '../../primitives/appTitle';

import PatternSvg from '../../components/svg/patternSvg';
import QuoteSvg from '../../components/svg/quoteSvg';
import SliderPhotographs from '../../components/sliderPhotographs';
import Callout from '../../components/callout';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';
import './frontAbout.less';
import { ApplyMountEffect, cardRevealEffect } from '../../helpers/reveals';

// @ts-ignore
import { TabsetPros } from 'legacyJs/tabset';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import { FrontAboutMessages } from './aboutPageTranslations';
import AboutPageEngagementsSection from './aboutPageEngagementsSection';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';
import useClientLogoImages from 'queries/useClientLogoImages';
import { ClientLogoName } from '../../appConstants';
import { graphql } from 'gatsby';
import { AboutPagePhotoQuery } from '../../appGraphQLTypes';
import {
  forceImageMaxWidth,
  getFluidImage,
  getFluidImages,
} from '../../appGatsbyImageUtils';
import { AppSection } from '../../primitives/appSection';
import { TheyTrustUsSection } from '../../components/theyTrustUsSection';
import ReactPlayer from 'react-player';
import { ShColors } from '@shoootin/design-tokens';

const useAboutPageLegacyJs = () => {
  useEffect(() => {
    new TabsetPros('.tabset-pledge');
  }, []);
};

/*
const AboutCharityGallery: Slide[] = [
  {
    captionTitle: 'Unicef',
    captionText:
      "Nos dons ont permis à Nala d'avoir des fournitures scolaires pour sa rentrée.",
    src: require('images/charity/slide_1.jpg'),
  },
  {
    captionTitle: 'Unicef',
    captionText:
      "Nos dons ont permis à Nala d'avoir des fournitures scolaires pour sa rentrée.",
    src: require('images/charity/slide_1.jpg'),
  },
];
*/

export interface Testimony {
  logo: ClientLogoName;
  quote: ShIntlMessageDefinition;
  author: string;
  position: ShIntlMessageDefinition;
  imgUrl: string;
}

const AboutTestimonies: Testimony[] = [
  {
    logo: 'belleval',
    imgUrl: '', // require('images/about/testimony-xavier.jpg'),
    author: 'Flore Jalenques',
    position: FrontAboutMessages.testimony1Position,
    quote: FrontAboutMessages.testimony1Quote,
  },
  {
    logo: 'kretz',
    imgUrl: '', // require('images/about/testimony-elisa.jpg'),
    author: 'Olivier Kretz',

    position: FrontAboutMessages.testimony2Position,
    quote: FrontAboutMessages.testimony2Quote,
  },
  {
    logo: 'cushman',
    imgUrl: '', // require('images/about/testimony-elisa.jpg'),
    author: 'Audrey Wozniak',

    position: FrontAboutMessages.testimony3Position,
    quote: FrontAboutMessages.testimony3Quote,
  },
  // {
  //   logo: 'jll.png',
  //   imgUrl: '', // require('images/about/testimony-julien.jpg'),
  //   author: 'Julien',
  //   position: 'Directeur de communication chez Lafarge',
  //   quote:
  //     'Un reportage photo très satisfaisant avec un photographe à l’écoute de nos besoins. Un bon début de collaboration !',
  // },
];

const StorySection = ({
  expertImage,
}: {
  expertImage: AppGatsbyImageFluid;
}) => (
  <AppSection
    className="about-story"
    header={
      <H2>
        <ShText message={FrontAboutMessages.pageSectionStoryTitle} />
      </H2>
    }
  >
    <div className="about-edito">
      <div className="about-edito__photo" data-parallax="0.2">
        <AppGatsbyImage fluid={expertImage} />
      </div>
      <div className="about-edito__caption" data-parallax="0.6">
        <p>
          <ShText message={FrontAboutMessages.pageSectionStoryDescription} />
        </p>
      </div>
      <div className="about-edito__pattern" data-parallax="0.4">
        <PatternSvg />
      </div>
    </div>
    <div className="about-quote" data-parallax="0.4">
      <QuoteSvg mode="gradient" />
      <blockquote>
        <ShText message={FrontAboutMessages.pageSectionStoryQuote} />
      </blockquote>
      <cite>
        <strong>
          <ShText message={FrontAboutMessages.pageSectionStoryAuthor} />
        </strong>
        <ShText message={FrontAboutMessages.pageSectionStoryAuthorRole} />
      </cite>
      <QuoteSvg mode="gradient-reverse" />
    </div>
  </AppSection>
);

const PhotographerSliderSection = () => (
  <AppSection
    header={
      <>
        <H2>
          <ShText message={FrontAboutMessages.pageSectionPhotographersTitle} />
        </H2>
        <p>
          <ShText
            message={FrontAboutMessages.pageSectionPhotographersDescription1}
          />
        </p>
        <p>
          <ShText
            message={FrontAboutMessages.pageSectionPhotographersDescription2}
          />
        </p>
      </>
    }
    className="darker"
  >
    <SliderPhotographs />
  </AppSection>
);

const PhotographerCallout = () => (
  <Callout imgSrc={require('images/about/callout.jpg')}>
    <H2>
      <ShText message={FrontAboutMessages.pageSectionPhotographersCtaTitle} />
    </H2>
    <p>
      <ShText
        message={FrontAboutMessages.pageSectionPhotographersCtaDescription}
      />{' '}
      <AppInternalLink
        to={AppInternalPaths.photographer}
        className="link invert"
      >
        <ShText message={FrontAboutMessages.pageSectionPhotographersCtaBtn} />
      </AppInternalLink>
    </p>
  </Callout>
);

const TestimonySection = () => {
  const logos = useClientLogoImages(AboutTestimonies.map((t) => t.logo));
  return (
    <AppSection
      header={
        <H2>
          <ShText message={FrontAboutMessages.pageSectionClientsTitle} />
        </H2>
      }
      className="gradient-tb"
    >
      <div className="testimonies">
        {AboutTestimonies.map((testimony, i) => (
          <ApplyMountEffect key={i} index={i} effect={cardRevealEffect}>
            {(ref) => (
              <div key={i} ref={ref} className="testimony card">
                <AppGatsbyImage
                  className="testimony__logo"
                  fluid={forceImageMaxWidth(logos[i], 300)}
                />
                <p>
                  « <ShText message={testimony.quote} /> »
                </p>
                <p className="testimony__author">
                  <strong>{testimony.author},</strong>{' '}
                  <ShText message={testimony.position} />
                </p>
                {/* <img className="testimony__avatar" src={testimony.imgUrl} /> */}
              </div>
            )}
          </ApplyMountEffect>
        ))}
      </div>
    </AppSection>
  );
};

const AboutPage = ({ data }: { data: AboutPagePhotoQuery }) => {
  const heroImage = getFluidImage(data.heroImage!);
  const expertImage = getFluidImage(data.expertImage!, 'expertImage');

  const [img1, img2, img3] = getFluidImages(data, 'engagementImages');
  const engagementImages = [img1, img2, img3];
  const locale = useShIntlLocale();
  // const videoId = locale === 'es_ES' ? 'AP4IZAEyEIw' : 'pnU24GWhsE8';
  useAboutPageLegacyJs();
  return (
    <MainLayout pageName="about">
      <HeroTitle imgSrc={heroImage}>
        <H1 className="hero-title">
          <ShText message={FrontAboutMessages.pageTitle} />
        </H1>
      </HeroTitle>

      <StorySection expertImage={expertImage} />

      <AppSection>
        <div
          css={{
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%',
            background: '#000',
          }}
        >
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=VGIT6iu29gE`}
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 0,
                  modestbranding: 1,
                  rel: 0,
                  color: ShColors.base,
                },
              },
            }}
          />
        </div>
      </AppSection>

      <AboutPageEngagementsSection images={engagementImages} />

      <PhotographerSliderSection />
      <PhotographerCallout />
      {locale !== 'en_US' && <TestimonySection />}
      <TheyTrustUsSection />

      {/* <Section
        header={
          <H2>
            <AppText message={FrontAboutMessages.pageSectionAssociationTitle} />
          </H2>
        }
        className="darker about-charity"
      >
        <div className="about-charity__grid">
          <div className="about-charity__pattern" data-parallax="0.4">
            <PatternSvg rainbow />
          </div>
          <div className="about-charity__caption" data-parallax="0.1">
            <p>
              <AppText
                message={FrontAboutMessages.pageSectionAssociationDescription1}
              />
            </p>
            <p>
              <AppText
                message={FrontAboutMessages.pageSectionAssociationDescription2}
              />
            </p>
          </div>
          <div
            className="gallery-slider about-charity__gallery"
            data-parallax="0.3"
          >
            <SliderWrapper
              slides={AboutCharityGallery}
            />
          </div>
        </div>
      </Section> */}
    </MainLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPagePhoto {
    heroImage: file(relativePath: { eq: "about_commitment_header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
    expertImage: file(relativePath: { eq: "about_commitment_expert.jpg" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 540, quality: 85, srcSetBreakpoints: [350, 450, 540]) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    engagementImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "about_commitment-*.jpg" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(
              maxWidth: 480
              quality: 85
              srcSetBreakpoints: [300, 400, 480]
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
